import React from 'react'
import { Layout, Quote } from '../components'
import './test-advice.scss'

const TestAdvice = () => {
  return (
    <Layout>
      <div className="section-test-advice">
        <div className="section-inner section-inner--s vertical-indent--m">
          <Quote
            quote="Так выглядит цитата. Например, Хочешь быть умным, научись разумно спрашивать, внимательно слушать, спокойно отвечать и переставать
            говорить, когда нечего больше сказать."
            author="Иоганн Каспар Лафатер"
          />
          <ul>
            <li>Так выглядит список</li>
            <li>Второй пункт</li>
            <li>Третий пункт и т.д.</li>
          </ul>
          <p>
            Так выглядит параграф. Под ним пример встроенного YouTube видео с таймкодами начала и конца. Это позволяет
            показывать фрагменты длинных видео.
          </p>
          <iframe
            width="600"
            height="420"
            src="https://www.youtube.com/embed/jjz7bxwsn6E?start=2135&end=2207"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}

export default TestAdvice
